import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Adjustment = _resolveComponent("Adjustment")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    title: _ctx.title,
    width: "1000px",
    footer: null,
    "destroy-on-close": true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        labelCol: { span: 5 },
        wrapperCol: { span: 18 },
        onSubmit: _cache[11] || (_cache[11] = _withModifiers(($event: any) => (_ctx.$emit('save', _ctx.form)), ["prevent"])),
        id: "myForm"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_descriptions, { title: "Inventory Details" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_descriptions_item, { label: "Date" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.form.date), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_descriptions_item, { label: "Grade" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.form.grade), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_descriptions_item, { label: "Tank" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.form.tank), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_descriptions_item, { label: "Site" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.form.site_name), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_divider, { style: {"margin-bottom":"8px","margin-top":"5px"} }),
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, { label: "Sales" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        type: "number",
                        value: _ctx.form.sales,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.sales) = $event)),
                        id: "code"
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, { label: "Remarks" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_textarea, {
                        value: _ctx.form.sales_remark,
                        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.sales_remark) = $event)),
                        rows: 2
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, { label: "Deliveries" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        type: "number",
                        value: _ctx.form.deliveries,
                        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.deliveries) = $event)),
                        id: "code"
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, { label: "Remarks" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_textarea, {
                        value: _ctx.form.deliveries_remark,
                        "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.deliveries_remark) = $event)),
                        rows: 2
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, { label: "Closing Stock" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        type: "number",
                        value: _ctx.form.closing_stock,
                        "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.closing_stock) = $event)),
                        id: "code"
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, { label: "Remarks" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_textarea, {
                        value: _ctx.form.closing_stock_remark,
                        "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.closing_stock_remark) = $event)),
                        rows: 2
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, { label: "Adjustments" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        type: "number",
                        value: _ctx.adjustments,
                        "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.adjustments) = $event)),
                        id: "adjustments",
                        disabled: ""
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, { label: "Remarks" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_textarea, {
                        value: _ctx.form.other_remark,
                        "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.other_remark) = $event)),
                        rows: 2
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, { label: "Book Stock" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        type: "number",
                        value: _ctx.computed_book_stock,
                        "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.computed_book_stock) = $event)),
                        id: "code",
                        disabled: ""
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, { label: "Variance" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        type: "number",
                        value: _ctx.computed_variance,
                        "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.computed_variance) = $event)),
                        id: "code",
                        disabled: ""
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { "wrapper-col": { span: 5, offset: 19 } }, {
            default: _withCtx(() => [
              _createVNode(_component_a_space, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_button, {
                    key: "back",
                    onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$emit('cancel')))
                  }, {
                    default: _withCtx(() => _cache[13] || (_cache[13] = [
                      _createTextVNode(" Cancel ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_a_button, {
                    key: "submit",
                    type: "primary",
                    htmlType: "submit",
                    loading: false
                  }, {
                    default: _withCtx(() => _cache[14] || (_cache[14] = [
                      _createTextVNode(" Submit ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_a_divider, { style: {"margin-bottom":"8px","margin-top":"5px"} }),
      _cache[15] || (_cache[15] = _createElementVNode("h3", { class: "title" }, "Adjustments", -1)),
      _createVNode(_component_DataTable, {
        loading: _ctx.loading == 'loading',
        data: _ctx.items.data,
        columns: _ctx.fields,
        pagination: _ctx.items,
        onCreate: _ctx.createRecord,
        onEdit: _ctx.editRecord,
        onDestroy: _ctx.deleteRecord
      }, null, 8, ["loading", "data", "columns", "pagination", "onCreate", "onEdit", "onDestroy"]),
      _createVNode(_component_Adjustment, {
        ref: "modal_form",
        title: "Adjustment",
        visible: _ctx.visible_adj,
        onCancel: _cache[12] || (_cache[12] = ($event: any) => (_ctx.visible_adj = false)),
        onSave: _ctx.handleSaving,
        data: _ctx.form_adjustment,
        "inventory-date": _ctx.form.date
      }, null, 8, ["visible", "onSave", "data", "inventory-date"])
    ]),
    _: 1
  }, 8, ["visible", "title"]))
}