import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    title: _ctx.title,
    footer: null,
    "destroy-on-close": true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        labelCol: { span: 5 },
        wrapperCol: { span: 18 },
        onSubmit: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.$emit('save', _ctx.form)), ["prevent"])),
        id: "myForm"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, { label: "Date" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_date_picker, {
                value: _ctx.value1,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value1) = $event)),
                format: _ctx.dateFormat,
                disabled: ""
              }, null, 8, ["value", "format"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { label: "Category" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                value: _ctx.form.adjustment_category_id,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.adjustment_category_id) = $event)),
                id: "adjustment_category_id",
                required: "",
                "show-search": "",
                filterOption: _ctx.filterOption,
                onChange: _ctx.getCategoryType
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.all_adjustment_categories, (item) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      key: item.id
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.name), 1)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value", "filterOption", "onChange"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { label: "Type" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                type: "text",
                value: _ctx.category_type,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.category_type) = $event)),
                id: "category_type",
                disabled: ""
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { label: "For Date*" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_date_picker, {
                value: _ctx.form.for_date,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.for_date) = $event)),
                format: _ctx.dateFormat,
                "disabled-date": _ctx.disabledDate
              }, null, 8, ["value", "format", "disabled-date"]),
              _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
              _cache[8] || (_cache[8] = _createElementVNode("small", null, "*You can only create adjustments for 2 days prior to inventory date.", -1))
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "Value",
            style: {"margin-top":"-15px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                type: "text",
                value: _ctx.form.value,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.value) = $event)),
                id: "value",
                required: ""
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { "wrapper-col": { span: 18, offset: 5 } }, {
            default: _withCtx(() => [
              _createVNode(_component_a_space, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_button, {
                    key: "back",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('cancel')))
                  }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode(" Cancel ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_a_button, {
                    key: "submit",
                    type: "primary",
                    htmlType: "submit",
                    loading: false
                  }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createTextVNode(" Submit ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["visible", "title"]))
}