import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible_remarks,
    title: _ctx.title,
    width: "800px",
    footer: null
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_col, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_row, { style: {"margin-bottom":"15px"} }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, {
                xs: {span: 24},
                lg: {span: 5}
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createElementVNode("strong", null, "Delivery Remark", -1)
                ])),
                _: 1
              }),
              _createVNode(_component_a_col, {
                xs: {span: 24},
                lg: {span: 15, offset: 2}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.form.deliveries_remark ?? '-'), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_a_col, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_row, { style: {"margin-bottom":"15px"} }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, {
                xs: {span: 24},
                lg: {span: 5}
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createElementVNode("strong", null, "Sales Remark", -1)
                ])),
                _: 1
              }),
              _createVNode(_component_a_col, {
                xs: {span: 24},
                lg: {span: 15, offset: 2}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.form.sales_remark ?? '-'), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_a_col, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_row, { style: {"margin-bottom":"15px"} }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, {
                xs: {span: 24},
                lg: {span: 5}
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createElementVNode("strong", null, "Adjustments Remark", -1)
                ])),
                _: 1
              }),
              _createVNode(_component_a_col, {
                xs: {span: 24},
                lg: {span: 15, offset: 2}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.form.adjustments_remark ?? '-'), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_a_col, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_row, { style: {"margin-bottom":"15px"} }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, {
                xs: {span: 24},
                lg: {span: 5}
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createElementVNode("strong", null, "Closing Stock Remark", -1)
                ])),
                _: 1
              }),
              _createVNode(_component_a_col, {
                xs: {span: 24},
                lg: {span: 15, offset: 2}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.form.closing_stock_remark ?? '-'), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["visible", "title"]))
}